import * as React from "react";
import { IInteractionItemProps } from "../../interfaces/IInteractionItemProps";
import { IStringAnswer } from "../../interfaces/IStringSelection";
import InteractionContentContainer from "../interaction-content-container/InteractionContentContainer";
import { Turnstile } from "@marsidev/react-turnstile";
import classNames from "classnames";
import PlaceholderLoadingSpinner from "../../ui/PlaceholderLoadingSpinner";
import { XCircleIcon } from "@heroicons/react/20/solid";

export interface ICaptchaAnswerProps
  extends IInteractionItemProps<IStringAnswer> {
  text: string;
  section: string;
}

export function CaptchaAnswer(props: ICaptchaAnswerProps) {
  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY ?? "";

  const [captchaIsLoading, setCaptchaIsLoading] =
    React.useState<boolean>(false);

  const [showError, setShowError] = React.useState<boolean>(false);

  return (
    <InteractionContentContainer
      section={props.section}
      hasBackButton={props.hasBackButton}
      onBackButtonClicked={props.onBackButtonClicked}
      id={props.id}
      isLoading={props.isLoading}
    >
      <div>
        <div className="pb-5 px-4 text-center text-md text-gray-700 dark:text-white font-semibold">
          {props.text}
        </div>

        {captchaIsLoading && (
          <div className="flex flex-col justify-center items-center gap-3 text-gray-700 dark:text-white w-full">
            <PlaceholderLoadingSpinner />
            <p>Loading captcha</p>
          </div>
        )}

        {showError && (
          <div className="flex flex-col justify-center items-center gap-3 text-gray-700 dark:text-white w-full">
            <XCircleIcon className="text-red-500 size-10" />
            An error occurred while loading the captcha
          </div>
        )}

        <div
          className={classNames(
            "flex justify-center",
            captchaIsLoading || showError ? "hidden" : ""
          )}
        >
          <Turnstile
            siteKey={captchaSiteKey}
            onError={(e) => {
              console.warn("An error occurred: " + e);
              setCaptchaIsLoading(false);
              setShowError(true);
            }}
            onExpire={() => console.warn("expired")}
            onLoadScript={() => setCaptchaIsLoading(true)}
            onWidgetLoad={() =>
              setTimeout(() => setCaptchaIsLoading(false), 500)
            }
            onSuccess={(x) =>
              x && props.completed && props.completed({ id: 1, value: x })
            }
          />
        </div>
      </div>
    </InteractionContentContainer>
  );
}
