import { useState } from "react";
import "./App.css";
import OptionAnswer from "./interaction-items/answers/OptionAnswer";
import TextInputAnswer from "./interaction-items/answers/text-input-answer.tsx/TextInputAnswer";

import { IAnonLeakCheck } from "./models/IAnonLeakCheck";
import { Platform } from "./models/Platform";

import Container from "typedi";
import { ILeakCheckApiCaller } from "./api/ILeakCheckApiCaller";
import { ServiceTokens } from "./util/ServiceTokens";
import { INotificationService } from "./notifications/NotificationService";
import { v4 as uuidv4 } from "uuid";
import { CaptchaAnswer } from "./interaction-items/answers/CaptchaAnswer";
import * as yup from "yup";
import CreatorInstantResult, {
  CreatorInstantResultAction,
} from "./interaction-items/results/creator-instant-result";
import { IContactInfo } from "./models/IContactInfo";
import { ContactMethod } from "./models/ContactMethod";
import { DeepSearchResult } from "./interaction-items/results/deep-search-result";
import useResizeObserver from "use-resize-observer";

function App() {
  const [currentlyShowingId, setCurrentlyShowingId] = useState<number>(20);
  const [leakCheckData, setLeakCheckData] = useState<IAnonLeakCheck>({
    id: uuidv4(),
    platform: Platform.OnlyFans,
    creatorHandle: "",
    captcha: "",
  });

  const [contactInfoData, setContactInfoData] = useState<IContactInfo>({
    preferredContactMethod: ContactMethod.Email,
    email: "",
    phone: "",
    telegramHandle: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const saveInformation = (data: IContactInfo) => {
    if (data.phone) {
      data.phone = data.phone.replace("+", "00");
    }

    setContactInfoData(data);

    setIsLoading(true);

    const apiCaller = Container.get<ILeakCheckApiCaller>(
      ServiceTokens.LeakCheckApiCallerServiceToken
    );

    apiCaller
      .addContactInfo(leakCheckData.id, data)
      .then((x) => {
        setContactInfoData(x);
        setCurrentlyShowingId(100);
      })
      .catch((err) => {
        const notificationService = Container.get<INotificationService>(
          ServiceTokens.NotificationServiceToken
        );

        notificationService.createError(
          "Could not submit contact information because of an error",
          "Error: " + err
        );
      })
      .finally(() => setIsLoading(false));
  };

  const flow: Record<number, React.ReactNode> = {
    1: (
      <OptionAnswer
        id={1}
        name="CreatorOrBrand"
        section="Start"
        question="Are you a creator or a brand?"
        options={["Creator", "Brand"]}
        hasBackButton={false}
        completed={(x) =>
          x.value === "Creator"
            ? setCurrentlyShowingId(20)
            : setCurrentlyShowingId(10)
        }
      ></OptionAnswer>
    ),

    // Brand branch
    10: (
      <OptionAnswer
        id={10}
        name="BrandWhatToProtect"
        section="Brand"
        question="What are you worried about?"
        options={["Copied products", "Brand name abuse"]}
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(1)}
        completed={(x) => setCurrentlyShowingId(11)}
      ></OptionAnswer>
    ),
    11: (
      <TextInputAnswer
        id={11}
        section="Brand"
        question="What is the name of your brand?"
        placeholder="Fancy name"
        name="BrandName"
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(10)}
        completed={(x) => setCurrentlyShowingId(12)}
      />
    ),
    12: (
      <TextInputAnswer
        id={12}
        section="Brand"
        question="Please provide a link to your website"
        placeholder="www.your-brand.com"
        name="BrandUrl"
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(11)}
        completed={(x) => setCurrentlyShowingId(13)}
      />
    ),
    13: (
      <TextInputAnswer
        id={13}
        section="Brand"
        question="Please provide a email to where the result should be sent"
        placeholder="person@your-brand.com"
        name="BrandEmail"
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(12)}
        completed={() => {}}
      />
    ),

    // Creator branch
    20: (
      <OptionAnswer
        id={20}
        name="CreatorWhatPlatform"
        section="Creator"
        question="What platform do you use to contribute your content?"
        options={["OnlyFans", "Instagram"]}
        completed={(x) => {
          const platform =
            x.value === "OnlyFans" ? Platform.OnlyFans : Platform.Instagram;

          setLeakCheckData({ ...leakCheckData, platform: platform });
          setCurrentlyShowingId(21);
        }}
      ></OptionAnswer>
    ),
    21: (
      <TextInputAnswer
        id={21}
        name="CreatorHandle"
        section="Creator"
        question="Please provide your creator handle"
        placeholder="your-handle"
        validator={yup.object({
          text: yup
            .string()
            .required("Please provide a creator handle")
            .max(
              128,
              "The creator handle can not be longer than 128 characters"
            )
            .matches(
              new RegExp("^[A-Za-z0-9_.-]+$"),
              "Please provide a valid creator handle"
            ),
        })}
        prefix={
          leakCheckData.platform === Platform.OnlyFans
            ? "onlyfans.com/"
            : "instagram.com/"
        }
        value={leakCheckData.creatorHandle}
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(20)}
        completed={(x) => {
          setLeakCheckData({
            ...leakCheckData,
            creatorHandle: x.value?.toLowerCase().trim() ?? "",
          });
          setCurrentlyShowingId(22);
        }}
      />
    ),
    22: (
      <OptionAnswer
        id={22}
        name="CreatorWhatPlatform"
        section="Creator"
        question="Does this link lead to your profile?"
        link={
          leakCheckData.platform === Platform.OnlyFans
            ? "https://onlyfans.com/" + leakCheckData.creatorHandle
            : "https://www.instagram.com/" + leakCheckData.creatorHandle
        }
        options={["Yes", "No"]}
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(21)}
        completed={(x) => {
          if (x.value === "Yes") {
            setCurrentlyShowingId(23);
          } else {
            setCurrentlyShowingId(21);
          }
        }}
      ></OptionAnswer>
    ),
    23: (
      <CaptchaAnswer
        id={23}
        text="Please complete the captcha"
        name="CreatorCaptcha"
        section="Creator"
        isLoading={isLoading}
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(21)}
        completed={(x) => {
          const currentData = { ...leakCheckData, captcha: x.value };
          setLeakCheckData(currentData);

          const apiCaller = Container.get<ILeakCheckApiCaller>(
            ServiceTokens.LeakCheckApiCallerServiceToken
          );

          setIsLoading(true);

          apiCaller
            .addAsAnon(currentData)
            .then((x) => {
              setLeakCheckData(x);
              setCurrentlyShowingId(24);
            })
            .catch((err) => {
              const notificationService = Container.get<INotificationService>(
                ServiceTokens.NotificationServiceToken
              );

              notificationService.createError(
                "Could not submit leak check request because of an error",
                "Error: " + err
              );

              setCurrentlyShowingId(22);
            })
            .finally(() => setIsLoading(false));
        }}
      />
    ),
    24: (
      <CreatorInstantResult
        id={24}
        name="CreatorInstantResult"
        section="Creator"
        links={leakCheckData.foundLinks ?? []}
        completed={(x) => {
          if (x.action === CreatorInstantResultAction.DeepSearch) {
            setCurrentlyShowingId(25);
          } else if (x.action === CreatorInstantResultAction.RemoveLeaks) {
            window.open("https://www.remove.tech/creator-protection", "_blank");
          }
        }}
      />
    ),

    25: (
      <OptionAnswer
        id={25}
        name="CreatorPreferredContactMethod"
        section="Creator"
        question="How should we contact you concerning the results?"
        options={["Email", "Telegram", "WhatsApp"]}
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(24)}
        completed={(x) => {
          let preferredContactMethod = ContactMethod.Email;
          let nextId = 26;

          if (x.value === "WhatsApp") {
            preferredContactMethod = ContactMethod.WhatsApp;
            nextId = 28;
          } else if (x.value === "Telegram") {
            preferredContactMethod = ContactMethod.Telegram;
            nextId = 27;
          }

          setContactInfoData({
            ...contactInfoData,
            preferredContactMethod: preferredContactMethod,
          });
          setCurrentlyShowingId(nextId);
        }}
      ></OptionAnswer>
    ),
    26: (
      <TextInputAnswer
        id={26}
        name="CreatorEmail"
        section="Creator"
        question="Please provide your email address"
        placeholder="a-email@gmail.com"
        isLoading={isLoading}
        validator={yup.object({
          text: yup
            .string()
            .required("Please provide a email address")
            .max(256, "The email address can not be longer than 256 characters")
            .matches(
              new RegExp("^[A-Za-z0-9_.-@]+$"),
              "Please provide a valid email address"
            ),
        })}
        value={contactInfoData.email}
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(25)}
        completed={(x) => {
          const email = x.value?.toLowerCase().trim() ?? "";
          const currentData = { ...contactInfoData, email: email };

          saveInformation(currentData);
        }}
      />
    ),
    27: (
      <TextInputAnswer
        id={27}
        name="CreatorTelegram"
        section="Creator"
        question="Please provide your telegram handle"
        placeholder="yourHandle"
        prefix="@"
        isLoading={isLoading}
        validator={yup.object({
          text: yup
            .string()
            .required("Please provide a telegram handle")
            .max(
              256,
              "The telegram handle can not be longer than 256 characters"
            )
            .matches(
              new RegExp("^[A-Za-z0-9_.-]+$"),
              "Please provide a valid telegram handle"
            ),
        })}
        value={contactInfoData.telegramHandle}
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(25)}
        completed={(x) => {
          const trimmed = x.value?.trim() ?? "";
          const currentData = { ...contactInfoData, telegramHandle: trimmed };

          saveInformation(currentData);
        }}
      />
    ),
    28: (
      <TextInputAnswer
        id={28}
        name="CreatorWhatsApp"
        section="Creator"
        question="Please provide your phone number"
        placeholder="+441234567"
        isLoading={isLoading}
        validator={yup.object({
          text: yup
            .string()
            .required("Please provide a telegram handle")
            .max(
              256,
              "The telegram handle can not be longer than 256 characters"
            )
            .matches(
              new RegExp("^\\+.*$"),
              "The phone number must include the international prefix (e.g +44)"
            )
            .matches(
              new RegExp("^\\+\\d{4,}$"),
              "The phone number should only contain numbers and no spaces"
            ),
        })}
        value={contactInfoData.phone}
        hasBackButton={true}
        onBackButtonClicked={() => setCurrentlyShowingId(25)}
        completed={(x) => {
          const trimmed = x.value?.trim() ?? "";
          const currentData = { ...contactInfoData, phone: trimmed };

          saveInformation(currentData);
        }}
      />
    ),

    100: (
      <DeepSearchResult
        id={100}
        name="DeepSearchResult"
        section="Creator"
        onRemoveLeaksClicked={() => {
          window.open("https://www.remove.tech/creator-protection", "_blank");
        }}
      />
    ),
  };

  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      const parent = window.parent;

      if (parent) {
        parent.postMessage(
          {
            height: height,
          },
          "*"
        );
      }
    },
  });

  return (
    <div ref={ref}>
      <div className="p-5 bg-transparent">{flow[currentlyShowingId]}</div>
    </div>
  );
}

export default App;
